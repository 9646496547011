import React from "react"
import MagnifyIcon from "mdi-react/MagnifyIcon"
import FileVideoIcon from "mdi-react/FileVideoIcon"
import ForumIcon from "mdi-react/ForumIcon"
import DownloadIcon from "mdi-react/DownloadIcon"
import AccountGroupIcon from "mdi-react/AccountGroupIcon"

import PageBody from "../components/page-body/pageBody"
import SEO from "../components/seo"
import { axios, TOAST_TIMER } from "../utils/utils"

import "./admin.scss"
import UserTabAdmin from "./../components/user-tab-admin/userTabAdmin"
import GroupTabAdmin from "./../components/group-tab-admin/groupTabAdmin"
import LoginModal from "./../components/login-modal/loginModal"
import Toast from "./../components/toast/toast"
import Input from "./../components/input/input"
import Loader from "./../components/loader/loader"
import VideoTabAdmin from "./../components/videoTabAdmin/videoTabAdmin"
import FaqTabAdmin from "./../components/faq-tab-admin/faqTabAdmin"
import FaqQuestionTabAdmin from "./../components/faq-question-tab-admin/faqQuestionTabAdmin"
import Button from "./../components/button/button"
import Forum from "./../pages/forum"

class Admin extends React.Component {
  constructor() {
    super()
    this.state = {
      admin: null,
      users: [],
      groups: [],
      videos: [],
      faqs: [],
      faqQuestions: [],
      forumPosts: { adminPosts: [], userPosts: [] },
      showSuccessToast: false,
      showErrorToast: false,
      showLoginErrorToast: false,
      showErrorFetchingDataToast: false,
      loginModalShowing: true,
      showUserSaveSuccessToast: false,
      showUserSaveErrorToast: false,
      showVideoSaveSuccessToast: false,
      showVideoSaveErrorToast: false,
      showStatsDownloadSuccessToast: false,
      showStatsDownloadErrorToast: false,
      showPostDeleteSuccessToast: false,
      showPostDeleteErrorToast: false,
      showCommentDeleteSuccessToast: false,
      showCommentDeleteErrorToast: false,
      showFaqDeleteSuccessToast: false,
      showFaqDeleteErrorToast: false,
      showFaqSaveSuccessToast: false,
      showFaqSaveErrorToast: false,
      showGroupsDeleteSuccessToast: false,
      showGroupsDeleteErrorToast: false,
      showGroupsSaveSuccessToast: false,
      showGroupsSaveErrorToast: false,
      search: "",
      openTab: -1,
      loading: true,
      resetForum: false,
    }
  }

  showToast = toastName => {
    return () => {
      this.setState(
        {
          [toastName]: true,
        },
        () => {
          setTimeout(() => {
            this.setState({ [toastName]: false })
          }, TOAST_TIMER)
        }
      )
    }
  }

  setUser = admin => {
    this.setState({ admin })
    this.getData(admin)
  }

  getData = admin => {
    var promises = []
    promises.push(
      axios({
        method: "GET",
        headers: { token: admin.token },
        url: "/admin/users",
      })
    )
    promises.push(
      axios({
        method: "GET",
        headers: { token: admin.token },
        url: "/admin/videos",
      })
    )
    promises.push(
      axios({
        method: "GET",
        headers: { token: admin.token },
        url: "/admin/faqs",
      })
    )
    promises.push(
      axios({
        method: "GET",
        headers: { token: admin.token },
        url: "/admin/faqQuestions",
      })
    )
    promises.push(
      axios({
        method: "GET",
        headers: { token: admin.token },
        url: "/admin/groups",
      })
    )

    Promise.all(promises)
      .then(results =>
        this.setState({
          users: results[0].data,
          videos: results[1].data,
          faqs: results[2].data,
          faqQuestions: results[3].data,
          groups: results[4].data,
          loading: false,
          openTab: 0,
        })
      )
      .catch(err => {
        this.showToast("showErrorFetchingDataToast")()
        console.log(err)
      })
  }

  onHideModal = () => {
    this.setState({
      loginModalShowing: false,
    })
  }

  saveUser = user => {
    const options = {
      method: "PATCH",
      headers: { token: this.state.admin.token },
      url: "/admin/users",
      data: user,
    }
    return axios(options)
      .then(result => {
        var newUsers = [...this.state.users]
        var indexToChange = newUsers.findIndex(
          user => user._id === result.data._id
        )
        newUsers[indexToChange] = result.data
        this.setState({ users: newUsers })
        this.showToast("showUserSaveSuccessToast")()
      })
      .catch(err => {
        this.showToast("showUserSaveErrorToast")()
        console.log(err)
      })
  }

  saveVideo = video => {
    const options = {
      method: video.blank ? "POST" : "PATCH",
      headers: { token: this.state.admin.token },
      url: "/admin/videos",
      data: video,
    }
    return axios(options)
      .then(result => {
        var newVideos = [...this.state.videos]
        var indexToChange = newVideos.findIndex(
          video => video._id === result.data._id
        )
        if (
          indexToChange === -1 &&
          this.state.videos[this.state.videos.length - 1]._id === "new"
        ) {
          indexToChange = this.state.videos.length - 1
        }
        newVideos[indexToChange] = result.data
        this.setState({ videos: newVideos })
        this.showToast("showVideoSaveSuccessToast")()
      })
      .catch(err => {
        this.showToast("showVideoSaveErrorToast")()
        console.log(err)
      })
  }

  deleteVideo = _id => {
    if (window.confirm("Are you sure you want to delete this video?")) {
      if (_id === "new") {
        var newVideos = [...this.state.videos]
        var indexToChange = newVideos.findIndex(video => video._id === "new")
        newVideos.splice(indexToChange, 1)
        this.setState({ videos: newVideos })
        this.showToast("showVideoDeleteSuccessToast")()
        return Promise.resolve()
      } else {
        const options = {
          method: "DELETE",
          headers: { token: this.state.admin.token },
          url: "/admin/videos",
          data: { _id },
        }
        return axios(options)
          .then(result => {
            var newVideos = [...this.state.videos]
            var indexToChange = newVideos.findIndex(
              video => video._id === result.data._id
            )
            newVideos.splice(indexToChange, 1)
            this.setState({ videos: newVideos })
            this.showToast("showVideoDeleteSuccessToast")()
          })
          .catch(err => {
            this.showToast("showVideoDeleteErrorToast")()
            console.log(err)
          })
      }
    } else {
      return Promise.resolve()
    }
  }

  saveAnswer = answer => {
    const options = {
      method: answer._id === "new" ? "POST" : "PATCH",
      headers: { token: this.state.admin.token },
      url: "/admin/faqs",
      data: answer,
    }
    return axios(options)
      .then(result => {
        result = result.data
        var newFaqs = [...this.state.faqs]
        var indexToChange = newFaqs.findIndex(faq => faq._id === result._id)
        if (~indexToChange) {
          newFaqs[indexToChange] = result
        } else {
          var newFaqIndex = newFaqs.findIndex(faq => faq._id === "new")
          newFaqs[newFaqIndex] = result
        }
        this.setState({ faqs: newFaqs })
        this.showToast("showFaqSaveSuccessToast")()
      })
      .catch(err => {
        this.showToast("showFaqSaveErrorToast")()
        console.log(err)
      })
  }

  deleteAnswer = _id => {
    if (window.confirm("Are you sure you want to delete this faq?")) {
      if (_id === "new") {
        var newFaqs = [...this.state.faqs]
        var indexToChange = newFaqs.findIndex(faq => faq._id === "new")
        newFaqs.splice(indexToChange, 1)
        this.setState({ faqs: newFaqs })
        this.showToast("showFaqDeleteSuccessToast")()
        return Promise.resolve()
      } else {
        const options = {
          method: "DELETE",
          headers: { token: this.state.admin.token },
          url: "/admin/faqs",
          data: { _id },
        }
        return axios(options)
          .then(result => {
            var newFaqs = [...this.state.faqs]
            var indexToChange = newFaqs.findIndex(
              faq => faq._id === result.data._id
            )
            newFaqs.splice(indexToChange, 1)
            this.setState({ faqs: newFaqs })
            this.showToast("showFaqDeleteSuccessToast")()
          })
          .catch(err => {
            this.showToast("showFaqDeleteErrorToast")()
            console.log(err)
          })
      }
    } else {
      return Promise.resolve()
    }
  }

  changeTab = tabNumber => {
    return () => {
      if (window.confirm("Make sure to save your changes before changing"))
        this.setState({ openTab: tabNumber })
    }
  }

  createNewVideo = page => {
    return () => {
      var newVideos = [...this.state.videos]
      newVideos.push({
        page,
        _id: "new",
        videoUrl: "",
        title: "",
        subtitle: "",
        description: "",
        blank: true,
      })
      this.setState({ videos: newVideos })
    }
  }

  createNewAnswer = () => {
    return () => {
      if (~this.state.faqs.findIndex(faq => faq._id === "new")) {
        return alert(
          "You already have a new unsaved answer, please save it before creating a new one."
        )
      }
      var newFaqs = [...this.state.faqs]
      newFaqs.push({
        _id: "new",
        title: "",
        body: "",
        category: "Create new category",
        order: newFaqs.length,
        resources: [],
        notSaved: true,
      })
      this.setState({ faqs: newFaqs })
    }
  }

  downloadStats = () => {
    this.setState({ loading: true })
    var options = {
      method: "GET",
      headers: { token: this.state.admin.token },
      url: "/admin/stats",
    }
    return axios(options)
      .then(result => {
        this.setState({ loading: false })
        this.showToast("showStatsDownloadSuccessToast")()
        var csv = result.data
        var date = new Date()
        var hiddenElement = document.createElement("a")
        hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv)
        hiddenElement.target = "_blank"
        hiddenElement.download = `health-smart-data-${date.getMonth() +
          1}-${date.getDate()}-${date.getFullYear()}.csv`
        hiddenElement.click()
      })
      .catch(err => {
        this.showToast("showStatsDownloadErrorToast")()
      })
  }

  deletePost = id => {
    console.log("deleting post", id)
    if (window.confirm("Are you sure you want to delete this post?")) {
      const options = {
        method: "DELETE",
        headers: { token: this.state.admin.token },
        url: "/admin/posts",
        data: { _id: id },
      }
      return axios(options)
        .then(result => {
          this.setState({ resetForum: true }, () =>
            this.setState({ resetForum: false })
          )
          this.showToast("showPostDeleteSuccessToast")()
        })
        .catch(err => {
          this.showToast("showPostDeleteErrorToast")()
        })
    }
  }

  deleteComment = id => {
    console.log("deleting comment", id)
    if (window.confirm("Are you sure you want to delete this comment?")) {
      const options = {
        method: "DELETE",
        headers: { token: this.state.admin.token },
        url: "/admin/comments",
        data: { _id: id },
      }
      return axios(options)
        .then(result => {
          this.setState({ resetForum: true }, () =>
            this.setState({ resetForum: false })
          )
          this.showToast("showCommentDeleteSuccessToast")()
        })
        .catch(err => {
          this.showToast("showCommentDeleteErrorToast")()
        })
    }
  }

  createGroup = () => {
    if (~this.state.groups.findIndex(group => group._id === "new")) {
      alert(
        "You already have an unsaved new group, please save before creating a new one"
      )
      return
    }
    var newGroups = [...this.state.groups]
    newGroups.unshift({
      letter: "A",
      color: "blue",
      members: [],
      _id: "new",
    })
    this.setState({ groups: newGroups })
  }

  saveGroup = group => {
    if (
      ~this.state.groups.findIndex(
        _group =>
          `${_group.letter}${_group.color}` ===
            `${group.letter}${group.color}` && _group._id !== group._id
      )
    ) {
      alert(
        `${group.color} ${group.letter} already exists, groups must be unique...`
      )
      return Promise.reject()
    }

    const options = {
      method: group._id === "new" ? "POST" : "PATCH",
      headers: { token: this.state.admin.token },
      url: "/admin/groups",
      data: group,
    }
    return axios(options)
      .then(result => {
        result = result.data
        var newGroups = [...this.state.groups]
        var indexToChange = newGroups.findIndex(
          group => group._id === result._id
        )
        if (~indexToChange) {
          newGroups[indexToChange] = result
        } else {
          var newGroupIndex = newGroups.findIndex(group => group._id === "new")
          newGroups[newGroupIndex] = result
        }
        this.setState({ groups: newGroups })
        this.showToast("showGroupsSaveSuccessToast")()
      })
      .catch(err => {
        this.showToast("showGroupsSaveErrorToast")()
        console.log(err)
        return Promise.reject()
      })
  }

  deleteGroup = _id => {
    if (!window.confirm("Are you sure you want to delete this faq?"))
      return Promise.resolve()

    if (_id === "new") {
      var newGroups = [...this.state.groups]
      var indexToChange = newGroups.findIndex(group => group._id === "new")
      newGroups.splice(indexToChange, 1)
      this.setState({ groups: newGroups })
      this.showToast("showGroupsDeleteSuccessToast")()
      return Promise.resolve()
    }

    const options = {
      method: "DELETE",
      headers: { token: this.state.admin.token },
      url: "/admin/groups",
      data: { _id },
    }
    return axios(options)
      .then(result => {
        var newGroups = [...this.state.groups]
        var indexToChange = newGroups.findIndex(
          group => group._id === result.data._id
        )
        newGroups.splice(indexToChange, 1)
        this.setState({ groups: newGroups })
        this.showToast("showGroupsDeleteSuccessToast")()
      })
      .catch(err => {
        this.showToast("showGroupsDeleteErrorToast")()
        console.log(err)
      })
  }

  render() {
    return (
      <>
        <SEO title="Home" keywords={["gatsby", "application", "react"]} />
        <div className="admin-page">
          <PageBody className="page">
            <LoginModal
              postURL="/admin/me"
              title="Admin Login"
              onHideModal={this.onHideModal}
              showSuccessToast={this.showToast("showSuccessToast")}
              showErrorToast={this.showToast("showErrorToast")}
              showLoginErrorToast={this.showToast("showLoginErrorToast")}
              show={this.state.loginModalShowing}
              setUser={this.setUser}
            />
            <Toast show={this.state.showSuccessToast} success>
              Login successful!
            </Toast>
            <Toast show={this.state.showErrorToast} error>
              Error logging in
            </Toast>
            <Toast show={this.state.showLoginErrorToast} error>
              Wrong username/password
            </Toast>
            <Toast show={this.state.showErrorFetchingDataToast} error>
              Error getting data
            </Toast>
            <Toast show={this.state.showUserSaveSuccessToast} success>
              Success saving user
            </Toast>
            <Toast show={this.state.showUserSaveErrorToast} error>
              Error saving user
            </Toast>
            <Toast show={this.state.showVideoSaveSuccessToast} success>
              Success saving video
            </Toast>
            <Toast show={this.state.showVideoSaveErrorToast} error>
              Error saving video
            </Toast>
            <Toast show={this.state.showVideoDeleteSuccessToast} success>
              Success deleting video
            </Toast>
            <Toast show={this.state.showVideoDeleteErrorToast} error>
              Error deleting video
            </Toast>
            <Toast show={this.state.showStatsDownloadSuccessToast} success>
              Success downloading stats file
            </Toast>
            <Toast show={this.state.showStatsDownloadErrorToast} error>
              Error downloading stats file
            </Toast>
            <Toast show={this.state.showPostDeleteSuccessToast} success>
              Success deleting post
            </Toast>
            <Toast show={this.state.showPostDeleteErrorToast} error>
              Error deleting post
            </Toast>
            <Toast show={this.state.showCommentDeleteSuccessToast} success>
              Success deleting Comment
            </Toast>
            <Toast show={this.state.showCommentDeleteErrorToast} error>
              Error deleting Comment
            </Toast>
            <Toast show={this.state.showFaqDeleteSuccessToast} success>
              Success deleting answer
            </Toast>
            <Toast show={this.state.showFaqDeleteErrorToast} error>
              Error deleting answer
            </Toast>
            <Toast show={this.state.showFaqSaveSuccessToast} success>
              Success saving answer
            </Toast>
            <Toast show={this.state.showFaqSaveErrorToast} error>
              Error saving answer
            </Toast>
            <Toast show={this.state.showGroupsSaveSuccessToast} success>
              Success saving group
            </Toast>
            <Toast show={this.state.showGroupsSaveErrorToast} error>
              Error saving group
            </Toast>
            <Toast show={this.state.showGroupsDeleteSuccessToast} success>
              Success deleting answer
            </Toast>
            <Toast show={this.state.showGroupsDeleteErrorToast} error>
              Error deleting answer
            </Toast>
            <div className="side-panel">
              <div className="category" onClick={this.changeTab(0)}>
                Users
              </div>
              <div className="category" onClick={this.changeTab(1)}>
                Groups
              </div>
              <div className="category" onClick={this.changeTab(2)}>
                Meeting Videos
              </div>
              <div className="category" onClick={this.changeTab(3)}>
                Tutorial Videos
              </div>
              <div className="category" onClick={this.changeTab(4)}>
                Statistics
              </div>
              <div className="category" onClick={this.changeTab(5)}>
                Forum Posts
              </div>
              <div className="category" onClick={this.changeTab(6)}>
                FAQ
              </div>
              <div className="category" onClick={this.changeTab(7)}>
                FAQ Questions
              </div>
            </div>
            <div className="main-panel">
              {this.state.loading ? (
                <Loader className="panel-loader" />
              ) : (
                <React.Fragment>
                  {this.state.openTab === 0 && <this.userPanel />}
                  {this.state.openTab === 1 && <this.groupPanel />}
                  {this.state.openTab === 2 && (
                    <this.videoPanel page="recorded-meetings" />
                  )}
                  {this.state.openTab === 3 && (
                    <this.videoPanel page="tutorials" />
                  )}
                  {this.state.openTab === 4 && !this.state.loading && (
                    <this.statsPanel />
                  )}
                  {this.state.openTab === 5 && <this.forumPanel />}
                  {this.state.openTab === 6 && <this.faqPanel />}
                  {this.state.openTab === 7 && <this.faqQuestionPanel />}
                </React.Fragment>
              )}
            </div>
          </PageBody>
        </div>
      </>
    )
  }

  userPanel = () => (
    <div className="user-panel">
      <div className="search">
        <MagnifyIcon />
        <Input
          className="search-box"
          placeholder="Search users"
          onChange={e => this.setState({ search: e.target.value })}
          value={this.state.search}
        />
      </div>
      <div className="user-warning">
        Please do not change admin NAME. It must be 'Admin'
      </div>
      {this.state.users
        .filter(
          user =>
            ~user.name.toLowerCase().indexOf(this.state.search.toLowerCase())
        )
        .map(user => (
          <UserTabAdmin key={user._id} {...user} saveUser={this.saveUser} />
        ))}
    </div>
  )

  groupPanel = () => {
    return (
      <div className="group-panel">
        <div className="search">
          <MagnifyIcon />
          <Input
            className="search-box"
            placeholder="Search groups"
            onChange={e => this.setState({ search: e.target.value })}
            value={this.state.search}
          />
        </div>
        <div className="add-group">
          <Button
            className="new-group-button"
            invert
            onClick={this.createGroup}
          >
            <AccountGroupIcon />
            <div className="inside-button">Create new group</div>
          </Button>
        </div>
        {this.state.groups
          .filter(
            group =>
              ~group.letter
                .toLowerCase()
                .indexOf(this.state.search.toLowerCase())
          )
          .map(group => (
            <GroupTabAdmin
              key={group._id}
              {...group}
              saveGroup={this.saveGroup}
              deleteGroup={this.deleteGroup}
              users={this.state.users}
            />
          ))}
      </div>
    )
  }

  videoPanel = ({ page }) => (
    <div className="video-panel">
      {this.state.videos
        .filter(video => video.page === page)
        .map(video => (
          <VideoTabAdmin
            key={video._id}
            saveVideo={this.saveVideo}
            deleteVideo={this.deleteVideo}
            {...video}
          />
        ))}
      <Button
        className="new-video-button"
        invert
        onClick={this.createNewVideo(page)}
      >
        <FileVideoIcon />
        <div className="inside-button">Create new video</div>
      </Button>
    </div>
  )

  statsPanel = () => (
    <div className="stats-panel">
      <div className="download-button">
        <Button invert onClick={this.downloadStats}>
          <DownloadIcon />
          <div className="inside-button">Download User Statistics</div>
        </Button>
      </div>
    </div>
  )

  forumPanel = () => (
    <div className="forum-panel">
      {!this.state.resetForum && (
        <React.Fragment>
          <div className="admin-forum-message">
            If you see endless loading, remember to login to the 'admin' user
            using the top right login button before coming to this page. If you
            are logged in, try and refresh the page.
          </div>
          <Forum
            user={this.state.admin.user}
            deleteComment={this.deleteComment}
            deletePost={this.deletePost}
            adminMode
          ></Forum>
        </React.Fragment>
      )}
    </div>
  )

  faqPanel = () => {
    var uniqueCategoriesSet = new Set(
      this.state.faqs.map(faq => faq.category).concat(["Create new category"])
    )
    var categories = Array.from(uniqueCategoriesSet)
    return (
      <div className="faq-panel">
        <Button
          className="new-answer-button"
          invert
          onClick={this.createNewAnswer()}
        >
          <ForumIcon />
          <div className="inside-button">Create new answer</div>
        </Button>
        {this.state.faqs.map(faq => (
          <FaqTabAdmin
            key={faq._id}
            saveAnswer={this.saveAnswer}
            deleteAnswer={this.deleteAnswer}
            categories={categories}
            {...faq}
          />
        ))}
      </div>
    )
  }

  faqQuestionPanel = () => {
    return (
      <div className="faq-questions-panel">
        {this.state.faqQuestions.length > 0 ? (
          this.state.faqQuestions.map(faqQuestion => (
            <FaqQuestionTabAdmin key={faqQuestion._id} {...faqQuestion} />
          ))
        ) : (
          <p>No questions available</p>
        )}
      </div>
    )
  }
}

export default Admin
