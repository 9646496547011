import React from "react"

import "./select.scss"

var Select = props => (
  <div
    className={`select-component ${props.className || ""} ${
      props.error ? "error" : ""
    }`}
  >
    <label htmlFor={props.name}>{props.name}</label>
    <p className="error-message">{props.errorMessage}</p>
    <select value={props.value} onChange={props.onChange}>
      {props.options &&
        props.options.map(option => <option value={option}>{option}</option>)}
    </select>
  </div>
)

export default Select
