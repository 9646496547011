import React from "react"

import "./datalist.scss"

var Datalist = props => (
  <div
    className={`datalist-component ${props.className || ""} ${
      props.error ? "error" : ""
    }`}
  >
    <label htmlFor={props.name}>{props.name}</label>
    <p className="error-message">{props.errorMessage}</p>
    <input
      list="datalist"
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange}
    />
    <datalist id="datalist">
      {props.options &&
        props.options.map(option => <option value={option}>{option}</option>)}
    </datalist>
  </div>
)

export default Datalist
