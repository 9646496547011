import React from "react"
import ChevronRightIcon from "mdi-react/ChevronRightIcon"
import ChevronDownIcon from "mdi-react/ChevronDownIcon"
import ContentSaveIcon from "mdi-react/ContentSaveIcon"

import "./userTabAdmin.scss"
import UserIcon from "./../user-icon/userIcon"
import { mapIconNameToIconSource, ICON_COLORS } from "../../utils/utils"
import Input from "./../input/input"
import Select from "./../select/select"
import Button from "./../button/button"
import Loader from "./../loader/loader"

class UserTabAdmin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      name: props.name,
      username: props.username,
      password: "",
      icon: props.icon,
      iconBackground: props.iconBackground,
      _id: props._id,
      loading: false,
    }
  }

  tabClick = () => {
    this.setState({ open: !this.state.open })
  }

  onInputChange = name => {
    return e => {
      this.setState({ [name]: e.target.value })
    }
  }

  saveUser = () => {
    var { name, username, password, icon, iconBackground, _id } = this.state
    this.setState({ loading: true }, () => {
      this.props
        .saveUser({
          name,
          username,
          password,
          icon,
          iconBackground,
          _id,
        })
        .then(() => {
          this.setState({
            loading: false,
          })
        })
    })
  }

  render() {
    return (
      <div className={`user-tab-admin-component ${this.props.className || ""}`}>
        <div className="tab" onClick={this.tabClick}>
          <UserIcon
            className="user-icon"
            icon={mapIconNameToIconSource[this.props.icon]}
            iconBackground={this.props.iconBackground}
          />
          <div className="user-name">{this.props.name}</div>
          <div className="grow" />
          <div className="caret">
            {this.state.open ? <ChevronDownIcon /> : <ChevronRightIcon />}
          </div>
        </div>
        {this.state.open && (
          <div className="body">
            <div className="top">
              <Input
                className="user-input"
                name="Name"
                value={this.state.name}
                onChange={this.onInputChange("name")}
              />
              <Input
                className="user-input"
                name="Username"
                value={this.state.username}
                onChange={this.onInputChange("username")}
              />
              <Input
                className="user-input"
                name="Password"
                onChange={this.onInputChange("password")}
              />
            </div>

            <div className="bottom">
              <Select
                className="user-select"
                name="Icon"
                options={Object.keys(mapIconNameToIconSource)}
                value={this.state.icon}
                onChange={this.onInputChange("icon")}
              />
              <Select
                className="user-select"
                name="Icon Color"
                options={ICON_COLORS}
                value={this.state.iconBackground}
                onChange={this.onInputChange("iconBackground")}
              />
            </div>
            <div className="save">
              <div className="grow" />
              {this.state.loading ? (
                <Loader className="save-loader" />
              ) : (
                <Button className="save-button" invert onClick={this.saveUser}>
                  <ContentSaveIcon />
                  <div className="button-text">Save</div>
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default UserTabAdmin
