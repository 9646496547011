import React from "react"

import "./videoTabAdmin.scss"
import Input from "./../input/input"
import TextArea from "./../text-area/textArea"
import Button from "./../button/button"
import Loader from "./../loader/loader"
import ContentSaveIcon from "mdi-react/ContentSaveIcon"
import DeleteIcon from "mdi-react/DeleteIcon"

class VideoTabAdmin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: props.title,
      subtitle: props.subtitle,
      videoUrl: props.videoUrl,
      description: props.description,
      loading: false,
    }
  }

  onInputChange = name => {
    return e => {
      this.setState({ [name]: e.target.value })
    }
  }

  saveVideo = () => {
    var { title, subtitle, videoUrl, description } = this.state
    this.setState({ loading: true }, () =>
      this.props
        .saveVideo({
          title,
          subtitle,
          videoUrl,
          description,
          _id: this.props._id,
          blank: this.props.blank,
          page: this.props.page,
        })
        .then(() => this.setState({ loading: false }))
    )
  }

  deleteVideo = () => {
    this.setState({ loading: true }, () =>
      this.props
        .deleteVideo(this.props._id)
        .then(() => this.setState({ loading: false }))
    )
  }
  /* eslint-disable */
  render() {
    return (
      <div className={`videoTabAdmin-component ${this.props.className || ""}`}>
        <div className="left">
          <iframe
            src={this.props.videoUrl}
            frameBorder="0"
            allow="fullscreen"
            allowFullScreen
          />
        </div>
        <div className="right">
          <div className="row">
            <Input
              className="input title-input"
              name="Title"
              value={this.state.title}
              onChange={this.onInputChange("title")}
            />
            <Input
              className="input subtitle-input"
              name="Subtitle"
              value={this.state.subtitle}
              onChange={this.onInputChange("subtitle")}
            />
          </div>
          <Input
            className="input url-input"
            name="Video URL"
            value={this.state.videoUrl}
            onChange={this.onInputChange("videoUrl")}
          />
          <TextArea
            height="15rem"
            name="description"
            placeholder="Describe the video..."
            value={this.state.description}
            onChange={this.onInputChange("description")}
          />
          <div className="save">
            <div className="grow" />
            {this.state.loading ? (
              <Loader className="save-loader" />
            ) : (
              <>
                <Button
                  className="delete-button"
                  invert
                  onClick={this.deleteVideo}
                >
                  <DeleteIcon />
                  <div className="button-text">Delete</div>
                </Button>
                <Button className="save-button" invert onClick={this.saveVideo}>
                  <ContentSaveIcon />
                  <div className="button-text">Save</div>
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default VideoTabAdmin
