import React, { useState } from "react"

import ChevronRightIcon from "mdi-react/ChevronRightIcon"
import ChevronDownIcon from "mdi-react/ChevronDownIcon"

import "./faqQuestionTabAdmin.scss"

var FaqQuestionTabAdmin = props => {
  var [open, setOpen] = useState(false)

  var formatDate = timestamp => {
    var date = new Date(timestamp)
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
  }

  return (
    <div
      className={`faq-question-tab-admin-component ${props.className || ""} ${
        open ? "open" : "closed"
      }`}
    >
      <div className="tab" onClick={() => setOpen(!open)}>
        <div className="title">{props.title}</div>
        <div className="date">{formatDate(props.timestamp)}</div>
        <div className="grow"></div>
        <div className="icon">
          {open ? <ChevronDownIcon /> : <ChevronRightIcon />}
        </div>
      </div>
      <div className="body">
        <span>Question:</span> {props.body}
        {/*
        <span>Email/Phone: {props.contact}</span>
        */}
      </div>
    </div>
  )
}

export default FaqQuestionTabAdmin
