import React from "react"

import "./faqTabAdmin.scss"
import Input from "./../input/input"
import TextArea from "./../text-area/textArea"
import Button from "./../button/button"
import Loader from "./../loader/loader"
import ContentSaveIcon from "mdi-react/ContentSaveIcon"
import LinkPlusIcon from "mdi-react/LinkPlusIcon"
import ChevronDownIcon from "mdi-react/ChevronDownIcon"
import ChevronRightIcon from "mdi-react/ChevronRightIcon"
import DeleteIcon from "mdi-react/DeleteIcon"
import Select from "../select/select"

class FaqTabAdmin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: props.title,
      category: props.category || props.categories[0],
      newCategory: "",
      newCategoryError: false,
      titleError: false,
      body: props.body,
      bodyError: false,
      resources: props.resources,
      open: props.title.length === 0,
      notSaved: props.notSaved,
    }
  }

  onInputChange = name => {
    return e => {
      this.setState({
        [name]: e.target.value,
        [`${name}Error`]: false,
        notSaved: true,
      })
    }
  }

  onResourceInputChange = (index, name) => {
    return e => {
      var newResources = [...this.state.resources]
      newResources[index][name] = e.target.value
      this.setState({ resources: newResources, notSaved: true })
    }
  }

  saveAnswer = () => {
    var { title, body, resources, category } = this.state
    var verified = true
    if (title.length <= 0) {
      this.setState({ titleError: true })
      verified = false
    }
    if (body.length <= 0) {
      this.setState({ bodyError: true })
      verified = false
    }
    if (
      this.state.category === "Create new category" &&
      this.state.newCategory.length <= 0
    ) {
      this.setState({ newCategoryError: true })
      verified = false
    }
    if (verified) {
      this.setState({ loading: true }, () => {
        if (category === "Create new category")
          category = this.state.newCategory
        this.props
          .saveAnswer({
            title,
            body,
            resources,
            category,
            _id: this.props._id,
          })
          .then(() => this.setState({ loading: false, notSaved: false }))
          .catch(() => this.setState({ loading: false }))
      })
    }
  }

  deleteAnswer = () => {
    this.setState({ loading: true }, () =>
      this.props
        .deleteAnswer(this.props._id)
        .then(() => this.setState({ loading: false }))
    )
  }

  deleteResource = index => {
    return () => {
      if (window.confirm("Are you sure you want to delete this resource?")) {
        var newResources = [...this.state.resources]
        newResources.splice(index, index + 1)
        this.setState({ resources: newResources, notSaved: true })
      }
    }
  }

  createNewResource = () => {
    var newResources = [...this.state.resources]
    newResources.push({ description: "", label: "", url: "" })
    this.setState({ resources: newResources })
  }

  toggleOpen = () => {
    this.setState({ open: !this.state.open })
  }

  /* eslint-disable */
  render() {
    return (
      <div className={`faq-tab-admin-component ${this.props.className || ""}`}>
        <div className="tab" onClick={this.toggleOpen}>
          {this.state.notSaved && <div className="is-saved">*NOT SAVED: </div>}
          <div className="tab-title">{this.state.title}</div>
          <div className="caret">
            {this.state.open ? <ChevronDownIcon /> : <ChevronRightIcon />}
          </div>
        </div>
        {this.state.open && (
          <React.Fragment>
            <div className="row">
              <Select
                className="category-select"
                name="Answer Category"
                options={this.props.categories}
                value={this.state.category}
                onChange={this.onInputChange("category")}
              />
              {this.state.category === "Create new category" && (
                <Input
                  className="input new-category-input"
                  name="New category name"
                  placeholder="Your new category name here"
                  value={this.state.newCategory}
                  onChange={this.onInputChange("newCategory")}
                  error={this.state.newCategoryError}
                  errorMessage={"Please enter a new category"}
                />
              )}
            </div>
            <Input
              className="input title-input"
              name="Question Title"
              placeholder="Your question title here"
              value={this.state.title}
              onChange={this.onInputChange("title")}
              error={this.state.titleError}
              errorMessage={"Please enter a title"}
            />
            <TextArea
              height="10rem"
              name="Answer Body"
              placeholder="Your answer here..."
              value={this.state.body}
              onChange={this.onInputChange("body")}
              error={this.state.bodyError}
              errorMessage={"Please enter a body"}
            />
            <p>RESOURCES</p>
            <div className="resources">
              {this.state.resources.map((resource, index) => (
                <div className="resource">
                  <Input
                    className="input resource-description-input"
                    name="Resource Description"
                    placeholder="Describe your resource here"
                    value={resource.description}
                    onChange={this.onResourceInputChange(index, "description")}
                  />
                  <div className="row">
                    <Input
                      className="input resource-label-input"
                      name="Resource Link Label"
                      placeholder="The hyperlink label (clickable blue text)"
                      value={resource.label}
                      onChange={this.onResourceInputChange(index, "label")}
                    />
                    <Input
                      className="input resource-url-input"
                      name="Resource Link url"
                      placeholder="The hyperlink url (clickable blue text)"
                      value={resource.url}
                      onChange={this.onResourceInputChange(index, "url")}
                    />
                  </div>
                  <Button
                    className="delete-button"
                    red
                    onClick={this.deleteResource(index)}
                  >
                    <DeleteIcon />
                    <div className="button-text">Delete Resource</div>
                  </Button>
                </div>
              ))}
            </div>
            <div className="save">
              {this.state.loading ? (
                <Loader className="save-loader" />
              ) : (
                <>
                  <Button
                    className="delete-button"
                    red
                    onClick={this.deleteAnswer}
                  >
                    <DeleteIcon />
                    <div className="button-text">Delete Answer</div>
                  </Button>
                  <Button
                    className="save-button"
                    invert
                    onClick={this.saveAnswer}
                  >
                    <ContentSaveIcon />
                    <div className="button-text">Save Answer</div>
                  </Button>
                  <div className="grow"></div>
                  <Button
                    className="new-resource-button"
                    invert
                    onClick={this.createNewResource}
                  >
                    <LinkPlusIcon />
                    <div className="inside-button">Create new resource</div>
                  </Button>
                </>
              )}
            </div>
          </React.Fragment>
        )}
      </div>
    )
  }
}

export default FaqTabAdmin
