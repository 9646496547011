import React, { useState } from "react"
import ChevronRightIcon from "mdi-react/ChevronRightIcon"
import ChevronDownIcon from "mdi-react/ChevronDownIcon"
import ContentSaveIcon from "mdi-react/ContentSaveIcon"
import DeleteIcon from "mdi-react/DeleteIcon"
import CloseIcon from "mdi-react/CloseIcon"

import Datalist from "./../datalist/datalist"
import Select from "./../select/select"
import UserIcon from "./../user-icon/userIcon"
import Button from "./../button/button"
import Loader from "./../loader/loader"
import {
  LETTERS,
  ICON_COLORS,
  mapIconNameToIconSource,
} from "../../utils/utils"

import "./groupTabAdmin.scss"

var GroupTabAdmin = props => {
  var [open, setOpen] = useState(props._id === "new")
  var [loading, setLoading] = useState(false)
  var [unsaved, setUnsaved] = useState(props._id === "new")

  var [letter, setLetter] = useState(props.letter)
  var [color, setColor] = useState(props.color)
  var [newMember, setNewMember] = useState("")
  var [members, setMembers] = useState(props.members)

  var addUser = () => {
    var user = props.users.find(user => user.name === newMember)
    if (user == null) return alert("Please select a valid user")
    if (~members.findIndex(member => member._id === user._id))
      return alert("User already in this group")
    var newMembers = [...members]
    newMembers.push(user)
    setMembers(newMembers)
    setNewMember("")
    setUnsaved(true)
  }

  var removeMember = (name, id) => {
    if (
      window.confirm(`Are you sure you want to remove ${name} from this group?`)
    ) {
      var newMembers = [...members]
      var indexToRemove = newMembers.findIndex(member => member._id === id)
      newMembers.splice(indexToRemove, 1)
      setMembers(newMembers)
      setUnsaved(true)
    }
  }

  var saveGroup = () => {
    if (members.length <= 0) {
      return alert(
        "Groups must not be empty, please fill in members before saving..."
      )
    }
    setLoading(true)
    props
      .saveGroup({
        members: members.map(member => member._id),
        letter,
        color,
        _id: props._id,
      })
      .then(() => {
        setLoading(false)
        setUnsaved(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  return (
    <div className={`group-tab-admin-component ${props.className || ""}`}>
      <div className="tab" onClick={() => setOpen(!open)}>
        {unsaved && <span className="unsaved">*NOT SAVED: </span>}
        <UserIcon
          className="group-icon"
          letter={letter}
          iconBackground={color}
        />
        <div className="group-letter">
          <span>Group:</span> {letter}
        </div>
        <div className="grow" />
        <div className="caret">
          {open ? <ChevronDownIcon /> : <ChevronRightIcon />}
        </div>
      </div>
      {open && (
        <div className="body">
          <div className="top">
            <Select
              className="form-group"
              name="Group Letter"
              options={LETTERS}
              value={letter}
              onChange={e => {
                setLetter(e.target.value)
                setUnsaved(true)
              }}
            />
            <Select
              className="form-group"
              name="Icon Color"
              options={ICON_COLORS}
              value={color}
              onChange={e => {
                setColor(e.target.value)
                setUnsaved(true)
              }}
            />
            <div className="form-group">
              <Datalist
                className="user-select"
                name="Available users"
                placeholder="Type in user name here..."
                options={props.users
                  .filter(
                    user =>
                      !~members.findIndex(member => member._id === user._id)
                  )
                  .map(user => user.name)}
                value={newMember}
                onChange={e => setNewMember(e.target.value)}
              />
              <Button className="add-user-button" invert onClick={addUser}>
                <div className="button-text">Add User To Group</div>
              </Button>
            </div>
          </div>
          <div>GROUP MEMBERS:</div>
          <div className="members-grid">
            {members.length > 0 ? (
              members.map(member => (
                <div className="member" key={member._id}>
                  <UserIcon
                    className="group-icon"
                    icon={mapIconNameToIconSource[member.icon]}
                    iconBackground={member.iconBackground}
                  />
                  <div className="member-name">{member.name}</div>
                  <Button
                    className="delete-button"
                    red
                    onClick={() => removeMember(member.name, member._id)}
                  >
                    <CloseIcon />
                  </Button>
                </div>
              ))
            ) : (
              <div className="empty">This group is empty</div>
            )}
          </div>
          <div className="save">
            {loading ? (
              <Loader className="save-loader" />
            ) : (
              <React.Fragment>
                <Button
                  className="delete-button"
                  red
                  onClick={() => props.deleteGroup(props._id)}
                >
                  <DeleteIcon />
                  <div className="button-text">Delete Group</div>
                </Button>
                <Button className="save-button" invert onClick={saveGroup}>
                  <ContentSaveIcon />
                  <div className="button-text">Save Group</div>
                </Button>
              </React.Fragment>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default GroupTabAdmin
